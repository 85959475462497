<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <h1 class="font-weight-bolder">
          {{ $t('myProjects') }}
        </h1>
        <div class="d-flex">
          <b-button
            :size="isSmallScreen?'sm':''"
            :variant="status ? 'outline-primary' : 'primary'"
            class="mr-2"
            @click="
              status = !status,
              fetchProjectsByStatus()
            "
          >
            <feather-icon
              icon="TrashIcon"
              size="14"
            />
          </b-button>
          <b-button
            :size="isSmallScreen?'sm':''"
            :to="{
              name: 'newProjects',
              params: { customerId: this.$route.params.customerId },
            }"
            variant="primary"
          >
            {{ $t('create', { type: $t('project') }) }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay :show="loading">
          <projects-table
            :projects="projects"
            @updateProjects="fetchProjectsByStatus()"
          />
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ProjectsTable from '@/components/projects/ProjectsTable.vue'

export default {
  components: {
    ProjectsTable,
  },

  data() {
    return {
      loading: false,
      status: true,
    }
  },

  computed: {
    ...mapGetters('projects', ['projects']),
    ...mapGetters('app', ['isSmallScreen']),
  },

  async created() {
    this.clearProject({})
    this.selectCustomerId(this.$route.params.customerId)
    try {
      this.loading = true
      await this.fetchProjects({
        customerId: this.$route.params.customerId,
        status: true,
      })
      this.loading = false
    } catch (error) {
      this.loading = false
    }
  },

  destroyed() {
    this.clearProjects()
  },

  methods: {
    ...mapActions('projects', ['fetchProjects']),
    ...mapMutations('projects', {
      clearProjects: 'CLEAR_PROJECTS',
      clearProject: 'SET_PROJECT',
    }),
    ...mapMutations('customers', {
      selectCustomerId: 'SELECT_CUSTOMER_ID',
    }),

    fetchProjectsByStatus() {
      this.loading = true
      this.fetchProjects({
        customerId: this.$route.params.customerId,
        status: !!this.status,
      }).then(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style></style>
