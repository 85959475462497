<template>
  <b-modal
    :id="id"
    :ok-title="$t('confirm')"
    :cancel-title="$t('cancel')"
    :ok-variant="variant"
    @ok="ok"
    @cancel="cancel"
  >
    <template #modal-title>
      {{ $t('confirm') }}
    </template>
    <div>
      <slot name="content" />
    </div>
  </b-modal>
</template>

<script>
export default {
  components: {},
  props: {
    id: {
      type: String,
      default: () => 'confirmModal',
    },
    variant: {
      type: String,
      default() {
        return 'primary'
      },
    },
  },
  data() {
    return {
      address: {},
    }
  },
  methods: {
    ok() {
      this.$emit('confirm')
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss" scoped></style>
