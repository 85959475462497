<template>
  <b-card
    class="overflow-hidden"
    no-body
  >
    <b-table
      :items="projects"
      :fields="fields"
      responsive
    >
      <template #cell(folio)="data">
        <b-button
          size="sm"
          variant="primary"
          class="font-weight-bolder"
          @click="goToProject(data.item)"
        >
          #{{ data.value }}
        </b-button>
      </template>
      <template #cell(name)="data">
        <div
          class="text-primary font-weight-bolder cursor-pointer"
          @click="goToProject(data.item)"
        >
          {{ data.value }}
        </div>
      </template>

      <template #cell(address)="data">
        <div style="min-width:180px">
          {{ data.value.formatted_address }},
        </div>
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          id="dropdown-1"
          size="sm"
          dropright
          no-caret
          variant="outline-none"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              color="#000"
            />
          </template>
          <b-dropdown-item
            :to="{ name: 'editProject', params: { projectId: data.item.id } }"
          >
            {{ $t('edit') }}
          </b-dropdown-item>
          <b-dropdown-item @click="openConfirmationModal(data.item)">
            <span
              :class="data.item.active_status ? 'text-danger' : 'text-success'"
            >
              {{
                data.item.active_status ? $t('deactivate') : $t('reactivate')
              }}
            </span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="data.item.active_status === false"
            @click="openDeleteModal(data.item)"
          >
            <span class="text-danger">
              {{ $t('delete') }}
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <confirmation-modal
      :id="'confirmChangeStatus'"
      :variant="projectToChange.active_status ? 'danger' : 'success'"
      @confirm="changeStatus(projectToChange)"
    >
      <template v-slot:content>
        {{
          projectToChange.active_status
            ? $t('deleteProjectConfirmation', {
              project: projectToChange.name,
            })
            : $t('reactivateProjectConfirmation', {
              project: projectToChange.name,
            })
        }}
      </template>
    </confirmation-modal>
    <confirmation-modal
      :id="'confirmDelete'"
      variant="danger"
      @confirm="removeProject(projectToDelete.id)"
    >
      <template v-slot:content>
        {{
          $t('definitiveDeleteProjectConfirmation', {
            project: projectToDelete.name,
          })
        }}
      </template>
    </confirmation-modal>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import helpers from '@/helpers'

export default {
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      projectToChange: {},
      projectToDelete: {},
      fields: [
        {
          key: 'folio',
          label: 'Folio',
        },
        {
          key: 'name',
          label: this.$t('name'),
        },
        {
          key: 'address',
          label: this.$t('address'),
          thClass: 'text-left',
          thStyle: 'width:60%;',
        },
        {
          key: 'actions',
          label: '',
          thClass: 'text-left',
          thStyle: '',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('projects', ['projects']),
  },

  methods: {
    ...mapActions('projects', ['editProject', 'deleteProject']),
    ...mapMutations('projects', {
      selectProject: 'SET_PROJECT',
    }),
    openConfirmationModal(supplier) {
      this.projectToChange = { ...supplier }
      this.$bvModal.show('confirmChangeStatus')
    },
    openDeleteModal(supplier) {
      this.projectToDelete = { ...supplier }
      this.$bvModal.show('confirmDelete')
    },
    changeStatus(project) {
      this.editProject({
        ...project,
        active_status: !project.active_status,
      }).then(() => {
        this.$emit('updateProjects')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('statusUpdated'),
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      })
    },
    removeProject(projectId) {
      this.deleteProject(projectId).then(() => {
        this.$emit('updateProjects')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('statusUpdated'),
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      })
    },
    goToProject(project) {
      this.selectProject(project)
      if (
        helpers.validZone(
          project.address?.state,
          project.address?.formatted_address,
        )
      ) {
        this.$router.push({
          name: 'quotes',
          params: { projectId: project.id },
        })
      } else {
        this.$router.push({
          name: 'groupedExpenses',
          params: { projectId: project.id },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
